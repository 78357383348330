import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const HostingVideo = lazy(() => import('sections/hero/HostingVideo.js'));
const Hosting = lazy(() => import('sections/services/Hosting.js'));
const Garantia30b = lazy(() => import('sections/hero/Garantia30b.js'));
const ServicesHosting = lazy(() => import('sections/services/ServicesHosting.js'));
const BotonHost = lazy(() => import('sections/services/BotonHost.js'));
const TecHosting = lazy(() => import('sections/services/TecHosting.js'));
const FaqHosting = lazy(() => import('sections/services/FaqHosting.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {

    // Datos estructurados para Producto
    const Service = [
      {
        "@context": "https://schema.org",        
        "@type": "Product",
        "name": "Hosting Personal",
        "description": "Ideal para emprendedores y Landing Pages.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "2",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia DirectAdmin",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "79997",  // Precio en pesos colombianos durante la oferta
          "priceValidUntil": "2024-12-11",
          
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            
            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",
            
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        },
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Corporativo",
        "description": "Ideal para empresas y tiendas virtuales.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "4",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia cPanel",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "119999",  // Precio en pesos colombianos durante la oferta
          "priceValidUntil": "2024-12-11",          
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            
            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",
            
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        },
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Básico",
        "description": "Ideal para pequeños negocios y sitios personales.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "44",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia DirectAdmin",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "99999",  // Precio en pesos colombianos
          "priceValidUntil": "2024-12-11",
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            
            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",
            
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        }
      },
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Hosting Empresarial",
        "description": "Ideal para sitios con altos volúmenes de usuarios.",
        "image": "https://solucionesenlaweb.com/img/vasco-solutions.webp",
        "sku": "89",
        "brand": {
          "@type": "Brand",
          "name": "VASCO Solutions"
        },
        "category": "Planes Hosting Colombia cPanel",
        "offers": {
          "@type": "Offer",
          "url": "https://solucionesenlaweb.com/hosting-colombia",
          "priceCurrency": "COP",
          "price": "179997",  // Precio en pesos colombianos
          "priceValidUntil": "2024-12-11",
          "availability": "https://schema.org/InStock",  // Disponibilidad del producto
          "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            
            "merchantReturnDays": "10",  // Número de días para la devolución
            "applicableCountry": [
              "CO",
              "US"
            ],
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn",
            
          },
          "shippingDetails": {
            "@type": "OfferShippingDetails",
            "shippingDestination": [
              {
                "@type": "DefinedRegion",
                "addressCountry": "CO"  // Envío a Colombia
              },
              {
                "@type": "DefinedRegion",
                "addressCountry": "US"  // Envío a Estados Unidos
              }
            ],
            "deliveryTime": {
              "@type": "ShippingDeliveryTime",
              "handlingTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de manejo en horas
              },
              "transitTime": {
                "@type": "QuantitativeValue",
                "minValue": 1,
                "maxValue": 1,
                "unitCode": "DAY"  // Tiempo de tránsito en días
              }
            },
            "shippingRate": {
              "@type": "MonetaryAmount",
              "value": "0",
              "currency": "USD"
            },
          }
        }
      }
    ]



    return (
      <div>
        <Helmet>
          <title>Hosting en Colombia: Servicios confiables a buen precio | Pereira</title>
          <meta name="description" content="Hosting confiable en Colombia y Pereira. Rendimiento óptimo y soporte excepcional. Precios baratos. Descubre la mejor opción para tu presencia en línea." />
          <script type="application/ld+json">
            {JSON.stringify(Service)}
          </script>
        </Helmet>
        <Layout>
          <HostingVideo />
          <Hosting />
          <Garantia30b />
          <BotonHost />
          <TecHosting />
          <ServicesHosting />
          <FaqHosting />
          <BotonHost />
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`